import React, { useEffect} from 'react';
import moment from 'moment';
import { Alert, Order } from 'stores/AlertsStore';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import EllipsisCell from 'components/EllipsisCell';
import PROPERTIES from 'properties';
import { Paper, Skeleton, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { EnhancedTableHead, HeadCell, StyledTableCell, StyledTableRow } from 'components/table/EnhancedTableHead';
import { Category, Severity, useAlertsStoreContext } from "stores/AlertsProvider";

const { EVENTS } = PROPERTIES

interface TableSkeletonProps {
    rowsNum?: number
    columnsNum?: number
}

function TableSkeleton ({rowsNum = 3, columnsNum = 5}: TableSkeletonProps) {
    return (
        <>
            {[...Array(rowsNum)].map((row, index) => (
                <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                        <Skeleton animation="wave" variant="text" />
                    </StyledTableCell>
                    {[...Array(columnsNum - 1)].map((column, index) => (
                        <StyledTableCell key={index}>
                            <Skeleton width={200} animation="wave" variant="text" />
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            ))}
        </>
    )
}

export type AlertKey = 'severity' | 'ip' | 'hostname' | 'timestamp' | 'summary' | 'description' | 'mitigated'

export function filterMitigatedColumn(categoryFilter: Category[], severityFilter: Severity[]): boolean {
    if ((categoryFilter.length > 0 && !categoryFilter.includes('detection')) ||
        (severityFilter.length === 1 && severityFilter[0] === 'siem'))
        return false
    return true
}

type AlertsTableProps = {
    alerts: Alert[]
    totalAlertsCount: number
    onChangePage: (newPage: number, oldPage: number) => void
    onChangeRowsPerPage: (rowsPerPage: number) => void
    loading: boolean
    rowsPerPage: number
    order: Order
    orderBy: AlertKey
    onSort: (order: Order, orderBy: string) => void
    categoryFilter: Category[]
    severityFilter: Severity[]
}

export function AlertsTable({ alerts, totalAlertsCount, onChangePage, onChangeRowsPerPage, onSort, loading, rowsPerPage, order = 'asc', orderBy = 'timestamp', categoryFilter, severityFilter}: AlertsTableProps) {
    const [page, setPage] = React.useState(0)
    const alertsStore = useAlertsStoreContext()

    useEffect(() => {
        if(alertsStore.cursorBefore === ""){
            setPage(0)
        }
    }, [alertsStore.cursorBefore])

    function handleRequestSort(event: React.MouseEvent<unknown>, property: string) {
        const isAsc = orderBy === property && order === 'asc'
        onSort(isAsc ? 'desc' : 'asc', property)
        setPage(0)
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        onChangePage(newPage, page)
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setPage(0)
        onChangeRowsPerPage(parseInt(event.target.value))
    }

    const headCells: readonly HeadCell[] = [
      {
        id: 'ip',
        disablePadding: false,
        label: EVENTS.IP,
      },
      {
        id: 'hostname',
        disablePadding: false,
        label: EVENTS.HOSTNAME,
      },
      {
        id: 'timestamp',
        disablePadding: false,
        label: EVENTS.TIME,
        sortable: true
      },
      {
        id: 'summary',
        disablePadding: false,
        label: EVENTS.SUMMARY,
      },
      {
        id: 'description',
        disablePadding: false,
        label: EVENTS.DESCRIPTION,
      },
      {
        id: 'mitigated',
        disablePadding: false,
        label: EVENTS.MITIGATED,
        showWhen: () => filterMitigatedColumn(categoryFilter, severityFilter)
      }
    ]

    const columnsForSkeleton = filterMitigatedColumn(categoryFilter, severityFilter) ? headCells.length : headCells.length - 1

    return (
        <Paper>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={alerts.length}
                        headCells={headCells}
                        />
                    <TableBody>
                        { loading ?
                            <TableSkeleton rowsNum={3} columnsNum={columnsForSkeleton}/>
                        :
                            alerts.map((alert, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{alert.device.ip_address}</StyledTableCell>
                                    <StyledTableCell>{alert.device.hostname}</StyledTableCell>
                                    <StyledTableCell>{moment.unix(alert.timestamp).format('MM/DD/YYYY, h:mm a')}</StyledTableCell>
                                    <StyledTableCell><EllipsisCell text={alert.threat_type}/></StyledTableCell>
                                    <StyledTableCell style={{textAlign: 'left'}}><EllipsisCell text={alert.description}/></StyledTableCell>
                                    { filterMitigatedColumn(categoryFilter, severityFilter) &&
                                      <StyledTableCell>
                                        { alert.category === 'detection' &&
                                          alert.mitigated !== null ?
                                              alert.mitigated ?
                                                  <BsCheckCircleFill color="green" /> :
                                                  <BsXCircleFill color="red" />
                                              : 'N/A'
                                        }
                                      </StyledTableCell>
                                    }
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={totalAlertsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
                sx={tablePaginationStyle}
            />
        </Paper>
    );
}

export const tablePaginationStyle = {
    backgroundColor: `#232328`
}
