import React, { useEffect} from 'react';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs'
import moment from 'moment';
import DeleteDeviceModal from "./EndpointsDeleteModal";
import { Paper, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { EnhancedTableHead, HeadCell, StyledTableCell, StyledTableRow } from 'components/table/EnhancedTableHead';
import { Order } from 'stores/AlertsStore';
import { Device } from 'stores/DevicesStore';
import { useDevicesStoreContext } from 'stores/DevicesProvider';
import { tablePaginationStyle } from '../home/AlertsTable';
import { StyledLink } from '../home/Home';

type EndpointsTableProps = {
    devices: Device[]
    onChangePage: (newPage: number, oldPage: number) => void
    onChangeRowsPerPage: (rowsPerPage: number) => void
    loading: boolean
    rowsPerPage: number
    order: Order
    orderBy: string
    onSort: (order: Order, orderBy: string) => void
    accountId: string
    cursorBefore: string
    cursorCurrent: string
}

export default function EndpointsTable({ devices, onChangePage, onChangeRowsPerPage, onSort, loading, rowsPerPage, accountId, order = 'asc', orderBy = 'hostname', cursorBefore, cursorCurrent } : EndpointsTableProps) {
    const [page, setPage] = React.useState(0)
    const devicesStore = useDevicesStoreContext()
    useEffect(() => {
        if(cursorBefore === ""){
            setPage(0)
        }
    }, [cursorBefore])

    function handleRequestSort(event: React.MouseEvent<unknown>, property: string) {
        const isAsc = orderBy === property && order === 'asc'
        onSort(isAsc ? 'desc' : 'asc', property)
        setPage(0)
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        onChangePage(newPage, page)
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setPage(0)
        onChangeRowsPerPage(parseInt(event.target.value))
    }

    async function handleDelete(device: Device) {
        let cursor = ''
        if (device.id !== devices[0].id)
            // If deleted device isn't the first one, cursor remains at the beginning of the list
            cursor = cursorCurrent
        else {
            if (devices.length !== 1)
                // If deleted device is the first one but there are more in the page, cursor will be the second one
                cursor = devices[1].id ? devices[1].id : ''
            else {
                // When deleted device is the only one on the page, cursor will be empty if there are no mor pages.
                // If there are previous pages, cursor will be cursorBefore
                if (page !== 1)
                    cursor = cursorBefore
            }
        }

        return devicesStore.deleteDevice(device)
            .then(() => devicesStore.loadDesktopData(rowsPerPage, cursor, accountId))
    }

    const headCells: readonly HeadCell[] = [
        {
            id: 'hostname',
            disablePadding: false,
            label: 'Hostname',
            sortable: true
        },
        {
            id: 'account_name',
            disablePadding: false,
            label: 'Account'
        },
        {
            id: 'agent_version',
            disablePadding: false,
            label: 'Version',
            sortable: true
        },
        {
            id: 'windows_configuration_name',
            disablePadding: false,
            label: 'Mem Protection List'
        },
        {
            id: 'last_heartbeat',
            disablePadding: false,
            label: 'Last Heartbeat'
        },
        {
            id: 'total_alerts',
            disablePadding: false,
            label: 'Alerts',
            sortable: true
        },
        {
            id: 'online_status',
            disablePadding: false,
            label: 'Online Status'
        },
        {
            id: 'delete',
            disablePadding: false,
            label: ''
        }
    ]

    return (
        <Paper>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={devices.length}
                        headCells={headCells}
                        />
                    <TableBody>
                        { devices.map((device, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell><StyledLink to={`/endpoint/${device.id}/monitoring`}>{device.hostname}</StyledLink></StyledTableCell>
                                <StyledTableCell>{device.account_name}</StyledTableCell>
                                <StyledTableCell>{device.agent_version}</StyledTableCell>
                                <StyledTableCell>{device.windows_configuration_name}</StyledTableCell>
                                <StyledTableCell>{device.last_heartbeat ? moment.utc(device.last_heartbeat).local().format('MM/DD/YYYY, h:mm A') : null}</StyledTableCell>
                                <StyledTableCell>{device.total_alerts}</StyledTableCell>
                                <StyledTableCell>{device.entropy_score && device.entropy_score >= 2 ? <BsCheckCircleFill color="green" /> : <BsXCircleFill color="red" />}</StyledTableCell>
                                <StyledTableCell>{<DeleteDeviceModal row={device} onConfirm={handleDelete}/>}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={devicesStore.desktopsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
                sx={tablePaginationStyle}
            />
        </Paper>
    )
}
